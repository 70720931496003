$color_1: #444444;
$color_2: #333333;
$color_3: #fff;
$color_4: #999;
$color_5: #dc4734;
$color_6: #666666;
$color_7: #ddd;
$font-family_1: "Roboto", sans-serif;
$background-color_1: #f1f1f1;
$background-color_2: transparent;
$background-color_3: #dc4734;
$background-color_4: #333333;
$background-color_5: rgba(51, 51, 51, 0.9);
$background-color_6: #fff;
$background-color_7: #ccc;
$background-color_8: #09ae82;
$background-color_9: rgba(0, 0, 0, 0.4);
$background-color_10: #e47263;
$border-color_1: #dc4734;

/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. hot deals area css start
5. product
6. categories area
7. testimonial
8. blog
9. shop grid view
10. shop list view
11. product details
12. checkout
13. cart
14. wishlist
15. my account
16. compare
17. login register
18. about us
19. contact us
20. banner
21. modal
22. sidebar
23. footer
-------------------------------------------------------------------*/
/*------ Typography Style Start ------*/
/*Custom Scroll (Perfect Scroll)*/
/* ----scroll to top css start ----*/
/* ----scroll to top css end ----*/
/*------- short classes start -------*/
/*------- short classes end -------*/
/*----------------------------------------
            header area start
----------------------------------------*/
/*----------------------------------------
            header area end
----------------------------------------*/
/*--------- secondary menu start ---------*/
/*--------- secondary menu end ---------*/
/*------ sticky menu style start ------*/
/*------ sticky menu style end ------*/
/*--------- mobile header start ---------*/
/*--------- mobile header end ---------*/
/*------- profile picture style start -------*/
/*------- widget title start -------*/
/*------- widget title end -------*/
/*------- like page list wrapper start -------*/
/*------- like page list wrapper end -------*/
/*--------- friend request list start ---------*/
/*--------- friend request list end ---------*/
/*--------- about author area start ---------*/
/*--------- about author area end ---------*/
/*------- photos section style start -------*/
/*------- photos section style end -------*/
/*-------- friend list area start --------*/
/*-------- friend list area end --------*/
/*------ photo filter area start ------*/
/*---------- footer area start ----------*/
/*-------- active profile carousel start --------*/
/*-------- active profile carousel end --------*/
/*------- friend search box start -------*/
/*------- friend search box end -------*/
/*------- live chat box start -------*/
/*------- live chat box end -------*/
/*------- mobile footer area start -------*/
/*------- mobile footer area end -------*/

// body {
//   color: $color_1;
//   line-height: 1.7;
//   font-size: 14px;
//   font-weight: 400;
//   background-color: $background-color_1;
//   font-family: $font-family_1;
// }
// a {
//   -webkit-transition: 0.4s;
//   -o-transition: 0.4s;
//   transition: 0.4s;
//   &:hover {
//     outline: none;
//     text-decoration: none;
//   }
//   &:focus {
//     outline: none;
//     text-decoration: none;
//   }
// }
// p {
//   margin-bottom: 8px;
//   &:last-child {
//     margin-bottom: 0;
//   }
// }
// h1 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// h2 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// h3 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// h4 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// h5 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// h6 {
//   color: $color_2;
//   margin-bottom: 0;
// }
// ul {
//   margin: 0;
//   padding: 0;
//   li {
//     list-style: none;
//   }
// }
// strong {
//   font-weight: 700;
// }
// b {
//   font-weight: 700;
// }
// figure {
//   margin: 0;
// }
// img {
//   max-width: 100%;
//   -webkit-transition: 0.4s;
//   -o-transition: 0.4s;
//   transition: 0.4s;
// }
// .bg-img {
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// }
// .btn {
//   border: none;
//   cursor: pointer;
//   outline: none;
//   padding: 0;
//   -webkit-box-shadow: none;
//   box-shadow: none;
//   -webkit-transition: 0.4s;
//   -o-transition: 0.4s;
//   transition: 0.4s;
//   background-color: $background-color_2;
//   &:active {
//     outline: none;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }
//   &:focus {
//     outline: none;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }
// }
// button {
//   border: none;
//   cursor: pointer;
//   outline: none;
//   padding: 0;
//   -webkit-box-shadow: none;
//   box-shadow: none;
//   -webkit-transition: 0.4s;
//   -o-transition: 0.4s;
//   transition: 0.4s;
//   background-color: $background-color_2;
//   &:active {
//     outline: none;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }
//   &:focus {
//     outline: none;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }
// }
// input {
//   resize: none;
//   &:focus {
//     outline: none;
//   }
// }
// textarea {
//   resize: none;
//   &:focus {
//     outline: none;
//   }
// }
// &::-moz-selection {
//   color: $color_3;
//   background: #dc4734;
// }
// &::selection {
//   color: $color_3;
//   background: #dc4734;
// }
// &::-webkit-input-placeholder {
//   color: $color_4;
//   font-size: 13px;
//   opacity: 1;
// }
// &::-moz-placeholder {
//   color: $color_4;
//   font-size: 13px;
//   opacity: 1;
// }
// &:-ms-input-placeholder {
//   color: $color_4;
//   font-size: 13px;
//   opacity: 1;
// }
// &::-ms-input-placeholder {
//   color: $color_4;
//   font-size: 13px;
//   opacity: 1;
// }
// &::placeholder {
//   color: $color_4;
//   font-size: 13px;
//   opacity: 1;
// }
.custom-scroll {
  position: relative;
  overflow: hidden;
}
.custom-scroll.ps {
  .ps__rail-y {
    background-color: $background-color_2;
    width: 3px;
    right: 2px;
    [class*="ps__thumb"] {
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .ps__thumb-y {
      width: 100%;
      right: 0;
    }
  }
  .ps__rail-x {
    background-color: $background-color_2;
    height: 6px;
    bottom: 3px;
    [class*="ps__thumb"] {
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .ps__thumb-x {
      height: 100%;
    }
  }
}
.slick-arrow-style {
  button.slick-arrow {
    top: 50%;
    left: 0;
    color: $color_2;
    font-size: 30px;
    cursor: pointer;
    display: block;
    line-height: 1;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    &:hover {
      color: $color_5;
    }
  }
  button.slick-arrow.slick-next {
    left: auto;
    right: 0;
  }
}
.slick-track {
  line-height: 1;
}
.slick-row-3 {
  .slick-list {
    margin: 0 -3px;
    .slick-slide {
      margin: 0 3px;
    }
  }
}
.slick-row-4 {
  .slick-list {
    margin: 0 -4px;
    .slick-slide {
      margin: 0 4px;
    }
  }
}
.slick-row-5 {
  .slick-list {
    margin: 0 -5px;
    .slick-slide {
      margin: 0 5px;
    }
  }
}
.slick-row-6 {
  .slick-list {
    margin: 0 -6px;
    .slick-slide {
      margin: 0 6px;
    }
  }
}
.slick-row-7 {
  .slick-list {
    margin: 0 -7px;
    .slick-slide {
      margin: 0 7px;
    }
  }
}
.slick-row-8 {
  .slick-list {
    margin: 0 -8px;
    .slick-slide {
      margin: 0 8px;
    }
  }
}
.slick-row-9 {
  .slick-list {
    margin: 0 -9px;
    .slick-slide {
      margin: 0 9px;
    }
  }
}
.slick-row-10 {
  .slick-list {
    margin: 0 -10px;
    .slick-slide {
      margin: 0 10px;
    }
  }
}
.slick-row-11 {
  .slick-list {
    margin: 0 -11px;
    .slick-slide {
      margin: 0 11px;
    }
  }
}
.slick-row-12 {
  .slick-list {
    margin: 0 -12px;
    .slick-slide {
      margin: 0 12px;
    }
  }
}
.slick-row-13 {
  .slick-list {
    margin: 0 -13px;
    .slick-slide {
      margin: 0 13px;
    }
  }
}
.slick-row-14 {
  .slick-list {
    margin: 0 -14px;
    .slick-slide {
      margin: 0 14px;
    }
  }
}
.slick-row-15 {
  .slick-list {
    margin: 0 -15px;
    .slick-slide {
      margin: 0 15px;
    }
  }
}
.slick-row-16 {
  .slick-list {
    margin: 0 -16px;
    .slick-slide {
      margin: 0 16px;
    }
  }
}
.slick-row-17 {
  .slick-list {
    margin: 0 -17px;
    .slick-slide {
      margin: 0 17px;
    }
  }
}
.slick-row-18 {
  .slick-list {
    margin: 0 -18px;
    .slick-slide {
      margin: 0 18px;
    }
  }
}
.slick-row-19 {
  .slick-list {
    margin: 0 -19px;
    .slick-slide {
      margin: 0 19px;
    }
  }
}
.slick-row-20 {
  .slick-list {
    margin: 0 -20px;
    .slick-slide {
      margin: 0 20px;
    }
  }
}
.scroll-top {
  bottom: 80px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  background-color: $background-color_3;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  i {
    line-height: 50px;
    color: $color_3;
    font-size: 25px;
  }
  &:hover {
    background-color: $background-color_4;
  }
}
.scroll-top.not-visible {
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
}
.row-0 {
  margin-left: 0px;
  margin-right: 0px;
  > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.row-1 {
  margin-left: -0.5px;
  margin-right: -0.5px;
  > [class*="col"] {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }
}
.row-2 {
  margin-left: -1px;
  margin-right: -1px;
  > [class*="col"] {
    padding-left: 1px;
    padding-right: 1px;
  }
}
.row-3 {
  margin-left: -1.5px;
  margin-right: -1.5px;
  > [class*="col"] {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
}
.row-4 {
  margin-left: -2px;
  margin-right: -2px;
  > [class*="col"] {
    padding-left: 2px;
    padding-right: 2px;
  }
}
.row-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
  > [class*="col"] {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
}
.row-6 {
  margin-left: -3px;
  margin-right: -3px;
  > [class*="col"] {
    padding-left: 3px;
    padding-right: 3px;
  }
}
.row-7 {
  margin-left: -3.5px;
  margin-right: -3.5px;
  > [class*="col"] {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
}
.row-8 {
  margin-left: -4px;
  margin-right: -4px;
  > [class*="col"] {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.row-9 {
  margin-left: -4.5px;
  margin-right: -4.5px;
  > [class*="col"] {
    padding-left: 4.5px;
    padding-right: 4.5px;
  }
}
.row-10 {
  margin-left: -5px;
  margin-right: -5px;
  > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.row-11 {
  margin-left: -5.5px;
  margin-right: -5.5px;
  > [class*="col"] {
    padding-left: 5.5px;
    padding-right: 5.5px;
  }
}
.row-12 {
  margin-left: -6px;
  margin-right: -6px;
  > [class*="col"] {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.row-13 {
  margin-left: -6.5px;
  margin-right: -6.5px;
  > [class*="col"] {
    padding-left: 6.5px;
    padding-right: 6.5px;
  }
}
.row-14 {
  margin-left: -7px;
  margin-right: -7px;
  > [class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
  > [class*="col"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
.row-16 {
  margin-left: -8px;
  margin-right: -8px;
  > [class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.row-17 {
  margin-left: -8.5px;
  margin-right: -8.5px;
  > [class*="col"] {
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
}
.row-18 {
  margin-left: -9px;
  margin-right: -9px;
  > [class*="col"] {
    padding-left: 9px;
    padding-right: 9px;
  }
}
.row-19 {
  margin-left: -9.5px;
  margin-right: -9.5px;
  > [class*="col"] {
    padding-left: 9.5px;
    padding-right: 9.5px;
  }
}
.row-20 {
  margin-left: -10px;
  margin-right: -10px;
  > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.row-21 {
  margin-left: -10.5px;
  margin-right: -10.5px;
  > [class*="col"] {
    padding-left: 10.5px;
    padding-right: 10.5px;
  }
}
.row-22 {
  margin-left: -11px;
  margin-right: -11px;
  > [class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
}
.row-23 {
  margin-left: -11.5px;
  margin-right: -11.5px;
  > [class*="col"] {
    padding-left: 11.5px;
    padding-right: 11.5px;
  }
}
.row-24 {
  margin-left: -12px;
  margin-right: -12px;
  > [class*="col"] {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.row-25 {
  margin-left: -12.5px;
  margin-right: -12.5px;
  > [class*="col"] {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
}
.row-26 {
  margin-left: -13px;
  margin-right: -13px;
  > [class*="col"] {
    padding-left: 13px;
    padding-right: 13px;
  }
}
.row-27 {
  margin-left: -13.5px;
  margin-right: -13.5px;
  > [class*="col"] {
    padding-left: 13.5px;
    padding-right: 13.5px;
  }
}
.row-28 {
  margin-left: -14px;
  margin-right: -14px;
  > [class*="col"] {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.row-29 {
  margin-left: -14.5px;
  margin-right: -14.5px;
  > [class*="col"] {
    padding-left: 14.5px;
    padding-right: 14.5px;
  }
}
.row-30 {
  margin-left: -15px;
  margin-right: -15px;
  > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.lg-backdrop {
  background-color: $background-color_5;
}
.lg-outer {
  .lg-thumb-outer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.bg-color {
  background-color: $background-color_1;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-80 {
  padding-top: 80px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt--20 {
  margin-top: -20px;
}
.mt--30 {
  margin-top: -30px;
}
.plr-80 {
  padding: 0 80px;
}
.header-top {
  -webkit-box-shadow: 0px 1px 20px 0px rgba(85, 85, 85, 0.25);
  box-shadow: 0px 1px 20px 0px rgba(85, 85, 85, 0.25);
}
.header-top-navigation {
  ul {
    li {
      display: inline-block;
      margin-right: 60px;
      position: relative;
      &:before {
        top: 50%;
        left: 100%;
        content: "/";
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        display: none;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &:before {
          display: none;
        }
      }
      span {
        color: $color_2;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        padding: 21px 0;
        text-transform: capitalize;
      }
      &:hover {
        a {
          color: $color_5;
        }
      }
      .message-dropdown {
        top: 100%;
        left: 0;
        width: 500px;
        height: auto;
        padding: 15px;
        position: absolute;
        background-color: $background-color_6;
        border-radius: 0 0 2px 2px;
        z-index: 999;
        display: none;
        -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
        box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
        li {
          a {
            padding: 0;
          }
        }
      }
    }
    li.active {
      a {
        color: $color_5;
      }
    }
  }
}
.dropdown-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .recent-msg {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $color_2;
    text-transform: capitalize;
  }
  .message-btn-group {
    button {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: $color_6;
      margin-left: 20px;
      &:hover {
        color: $color_5;
        text-decoration: underline;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  button {
    &:hover {
      color: $color_5;
    }
  }
}
.msg-dropdown-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  button {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: $color_6;
    &:hover {
      color: $color_5;
      text-decoration: underline;
    }
  }
}
.dropdown-msg-list {
  li {
    margin-right: 0;
  }
  li.msg-list-item {
    cursor: pointer;
    margin-right: 0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddddd;
    .profile-thumb {
      width: 45px;
    }
    .msg-content {
      padding-left: 10px;
      width: calc(100% - 150px);
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4;
        padding-top: 3px;
      }
    }
    .msg-content.notification-content {
      a {
        display: inline-block;
        color: $color_2;
        &:hover {
          color: $color_5;
        }
      }
      p {
        display: inline;
      }
    }
    .msg-time {
      font-size: 12px;
      color: $color_6;
      width: 100px;
      text-align: right;
    }
    .author {
      a {
        color: $color_2;
        &:hover {
          color: $color_5;
        }
      }
    }
  }
}
.header-top-search {
  margin-right: 25px;
}
.top-search-box {
  min-width: 250px;
  position: relative;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.top-search-field {
  border: none;
  width: 100%;
  padding-right: 30px;
  background-color: $background-color_2;
}
.top-search-btn {
  top: 0;
  right: 0;
  font-size: 20px;
  position: absolute;
  color: $color_2;
  &:hover {
    color: $color_5;
  }
}
.secondary-menu-wrapper {
  padding-left: 300px;
  padding-right: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .header-top-navigation {
    padding-right: 50px;
    text-align: right;
    width: 100%;
    li {
      margin-right: 47px;
      span {
        font-size: 14px;
        font-weight: 500;
        padding: 28px 0;
        text-transform: capitalize;
        i {
          display: none;
          font-size: 18px;
        }
      }
    }
  }
  .post-settings-bar {
    margin-left: inherit;
  }
}
.page-title {
  font-size: 18px;
  font-weight: 700;
  color: $color_2;
  text-transform: capitalize;
}
.sticky {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
}
.mobile-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: $background-color_6;
  height: 60px;
}
.mobile-logo {
  width: 80px;
  height: 100%;
  text-align: center;
  background-color: $background-color_3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .profile-thumb-middle {
    border-radius: 0;
  }
}
.mobile-header-profile {
  width: 80px;
  height: 100%;
  text-align: center;
  background-color: $background-color_3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .profile-thumb-middle {
    border-radius: 0;
  }
}
.mobile-menu {
  ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    .message-list {
      display: inherit;
    }
    li {
      i {
        font-size: 20px;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
      }
      &:hover {
        i {
          color: $color_5;
        }
      }
    }
  }
}
.notification {
  position: relative;
  span {
    top: -5px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $color_3;
    font-size: 10px;
    line-height: 20px;
    display: block;
    position: absolute;
    text-align: center;
    background-color: $background-color_3;
  }
}
.mob-search-box {
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}
.mob-search-box.show {
  opacity: 1;
  visibility: visible;
}
.mob-search-inner {
  position: relative;
}
.mob-search-field {
  width: 100%;
  border: none;
  height: 70px;
  padding: 15px 50px 15px 15px;
  background-color: $background-color_6;
  -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
}
.mob-search-btn {
  top: 50%;
  right: 10px;
  position: absolute;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  &:hover {
    color: $color_5;
  }
}
.search-trigger {
  position: relative;
  .close-icon {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.search-trigger.show {
  .search-icon {
    opacity: 0;
    visibility: hidden;
  }
  .close-icon {
    opacity: 1;
    visibility: visible;
  }
}
.mobile-chat-box {
  left: 0;
  top: 100%;
  width: 100%;
  height: 100vh;
  background-color: $background-color_6;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 999;
}
.mobile-chat-box.show {
  opacity: 1;
  visibility: visible;
}
.mob-text-box {
  bottom: 0;
  width: 100%;
  position: fixed;
  background-color: $background-color_1;
  padding: 25px;
}
.main-wrapper {
  padding-bottom: 105px;
}
.card {
  border: none;
  border-radius: 0;
  padding: 30px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  &:last-child {
    margin-bottom: 0;
  }
}
.card-small {
  padding: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card-profile {
  padding: 0;
}
.card.widget-item {
  padding: 25px;
}
.share-box-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.share-text-box {
  position: relative;
  padding-left: 15px;
  .share-text-field {
    width: 100%;
    border: none;
    display: block;
    height: 44px;
    padding: 13px 90px 13px 20px;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    background-color: $background-color_1;
    -webkit-box-shadow: inset 0px 1px 10px 0px rgba(85, 85, 85, 0.2);
    box-shadow: inset 0px 1px 10px 0px rgba(85, 85, 85, 0.2);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .btn-share {
    top: 50%;
    right: 3px;
    font-size: 12px;
    font-weight: 700;
    color: $color_3;
    line-height: 1;
    padding: 11px 25px;
    margin-right: 2px;
    border-radius: 40px;
    background-color: $background-color_3;
    position: absolute;
    text-transform: uppercase;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    &:hover {
      background-color: $background-color_4;
    }
  }
}
.modal-dialog {
  max-width: 600px;
}
.post-share-btn {
  color: $color_3;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 14px 30px 12px;
  border-radius: 40px;
  background-color: $background-color_3;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background-color: $background-color_4;
  }
}
.share-field-big {
  width: 100%;
  padding: 15px;
  border: 1px solid #dddddd;
  height: 200px !important;
}
.posted-author {
  margin-left: 20px;
  .post-time {
    display: block;
    font-size: 14px;
    line-height: 1;
    padding-top: 10px;
  }
}
.author {
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  text-transform: capitalize;
  a {
    color: $color_2;
    &:hover {
      color: $color_5;
      text-decoration: underline;
    }
  }
}
.post-settings-bar {
  margin-left: auto;
  position: relative;
  cursor: pointer;
  span {
    width: 20px;
    height: 1px;
    display: block;
    background-color: $background-color_7;
    margin-bottom: 4px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    span {
      background-color: $background-color_3;
    }
    .post-settings {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px);
    }
  }
}
.post-settings {
  right: 0;
  top: 100%;
  width: 200px;
  background-color: $background-color_6;
  position: absolute;
  padding: 20px;
  z-index: 9;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    button {
      color: $color_2;
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
      &:hover {
        color: $color_5;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }
}
.arrow-shape {
  &:before {
    right: 10px;
    bottom: 100%;
    content: "";
    position: absolute;
    height: 20px;
    border-bottom: 20px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}
.post-thumb-gallery {
  border-radius: 5px;
  overflow: hidden;
}
.post-thumb {
  img {
    width: 100%;
  }
}
.post-content {
  padding-top: 18px;
}
.post-desc {
  padding-bottom: 16px;
}
.post-desc-secondary {
  padding-top: 18px;
  padding-bottom: 0;
}
.post-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 12px;
  margin-top: 20px;
  .post-meta-like {
    strong {
      display: none;
      font-size: 14px;
      font-weight: 400;
      padding-left: 8px;
      color: $color_6;
    }
  }
  .comment-share-meta {
    margin-left: auto;
    li {
      display: inline-block;
      margin-left: 24px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.post-meta-like {
  i {
    font-size: 20px;
    color: $color_2;
    vertical-align: middle;
  }
  span {
    color: $color_6;
    vertical-align: text-bottom;
    padding-left: 8px;
  }
  &:hover {
    i {
      color: $color_5;
    }
    span {
      color: $color_5;
    }
  }
}
.post-comment {
  i {
    font-size: 20px;
    color: $color_2;
    vertical-align: middle;
  }
  span {
    color: $color_6;
    vertical-align: text-bottom;
    padding-left: 8px;
  }
  &:hover {
    i {
      color: $color_5;
    }
    span {
      color: $color_5;
    }
  }
}
.post-share {
  i {
    font-size: 20px;
    color: $color_2;
    vertical-align: middle;
  }
  span {
    color: $color_6;
    vertical-align: text-bottom;
    padding-left: 8px;
  }
  &:hover {
    i {
      color: $color_5;
    }
    span {
      color: $color_5;
    }
  }
}
.plyr--video {
  border-radius: 5px;
}
.plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}
.profile-menu-area {
  margin-bottom: 20px;
}
.profile-thumb-small {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
.profile-setting-box {
  position: relative;
  .profile-thumb-small {
    a {
      display: block;
    }
  }
  .profile-dropdown {
    top: 100%;
    right: 0;
    width: 250px;
    background-color: $background-color_6;
    position: absolute;
    z-index: 1;
    border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
    display: none;
  }
  .profile-head {
    padding: 20px;
    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 7px;
      a {
        color: $color_2;
        &:hover {
          color: $color_5;
        }
      }
    }
    .mail {
      font-size: 13px;
      color: $color_1;
      display: block;
      &:hover {
        color: $color_5;
      }
    }
  }
  .profile-body {
    ul {
      padding: 20px 0;
      border-top: 1px solid #dddddd;
      li {
        line-height: 1;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $color_1;
          padding: 0 20px;
          i {
            font-size: 16px;
            padding-right: 8px;
          }
        }
        &:hover {
          a {
            color: $color_5;
          }
        }
      }
    }
  }
}
.profile-thumb {
  display: inline-block;
  position: relative;
}
.profile-thumb-middle {
  width: 45px;
  height: 45px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
.profile-thumb.active {
  overflow: visible;
  img {
    border-radius: 50%;
  }
  &:before {
    bottom: 2px;
    right: -2px;
    width: 10px;
    height: 10px;
    content: "";
    border-radius: 50%;
    position: absolute;
    background-color: $background-color_8;
  }
}
.profile-banner-small {
  position: relative;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    pointer-events: none;
    border: 10px solid rgba(198, 165, 107, 0.3);
  }
}
.profile-thumb-2 {
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  bottom: -40px;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}
.profile-desc {
  padding: 30px;
  margin-top: 25px;
  p {
    font-size: 13px;
    color: $color_6;
    padding-top: 10px;
  }
}
.profile-banner-large {
  height: 90px;
}
.main-menu {
  li {
    display: inline-block;
  }
}
.profile-edit-panel {
  text-align: right;
}
.edit-btn {
  color: $color_3;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  padding: 11px 28px;
  display: inline-block;
  border-radius: 50px;
  text-transform: capitalize;
  background-color: $background-color_3;
  &:hover {
    background-color: $background-color_4;
  }
}
.profile-picture-box {
  position: absolute;
  -webkit-transform: translateY(calc(-50% - 10px));
  -ms-transform: translateY(calc(-50% - 10px));
  transform: translateY(calc(-50% - 10px));
  background-color: $background-color_6;
  z-index: 1;
}
.secondary-navigation-style {
  .profile-picture-box {
    -webkit-transform: translateY(calc(-50% - 17px));
    -ms-transform: translateY(calc(-50% - 17px));
    transform: translateY(calc(-50% - 17px));
  }
}
.profile-picture {
  position: relative;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    pointer-events: none;
    border: 10px solid #fff;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile-sidebar {
  margin-top: 105px;
}
.author-into-list {
  margin-top: 27px;
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $color_2;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      &:hover {
        color: $color_5;
      }
      i {
        color: $color_5;
        font-size: 20px;
        padding-right: 20px;
      }
    }
  }
}
.widget-title {
  font-size: 18px;
  font-weight: 700;
  color: $color_2;
  margin-bottom: 45px;
  text-transform: capitalize;
  position: relative;
  &:before {
    bottom: -12px;
    left: 0;
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    background-color: $background-color_3;
  }
}
.like-page-list-wrapper {
  li {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.unorder-list-info {
  padding-left: 10px;
}
.list-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: 3px;
  a {
    color: $color_2;
    &:hover {
      color: $color_5;
    }
  }
}
.list-subtitle {
  font-size: 12px;
  font-weight: 400;
  color: $color_6;
  text-transform: capitalize;
  a {
    color: $color_6;
    &:hover {
      color: $color_5;
    }
  }
}
.unorder-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  .like-button {
    font-size: 20px;
    margin-left: auto;
    position: relative;
    .heart-color {
      top: 50%;
      left: 0;
      position: absolute;
      opacity: 1;
      visibility: hidden;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
    }
    &:hover {
      .heart {
        opacity: 0;
        visibility: hidden;
      }
      .heart-color {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .like-button.active {
    .heart {
      opacity: 0;
      visibility: hidden;
    }
    .heart-color {
      opacity: 1;
      visibility: visible;
    }
  }
}
.sweet-galley {
  margin-bottom: -5px;
}
.gallery-tem {
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden;
}
.frnd-request-list {
  background-color: $background-color_6;
  padding: 25px;
  width: 100%;
  display: inherit !important;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  transform: translateY(13px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  li {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }
}
.frnd-request-list.show {
  opacity: 1;
  visibility: visible;
}
.frnd-request-member {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .request-thumb {
    width: 80px;
    max-height: 80px;
  }
}
.frnd-content {
  padding-left: 15px;
  width: calc(100% - 80px);
  p {
    font-size: 13px;
    color: $color_6;
    padding-top: 6px;
  }
}
.request-btn-inner {
  margin-top: 5px;
  .frnd-btn {
    font-size: 13px;
    font-weight: 500;
    color: $color_3;
    text-transform: capitalize;
    line-height: 1;
    padding: 8px 20px;
    display: inline-block;
    border: 1px solid transparent;
    background-color: $background-color_3;
    &:hover {
      background-color: $background-color_4;
    }
  }
  .frnd-btn.delete {
    color: $color_2;
    border: 1px solid #333333;
    background-color: $background-color_2;
    &:hover {
      color: $color_3;
    }
  }
}
.signup-form-wrapper {
  .create-acc {
    color: $color_5;
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 47px;
  }
}
.signup-inner {
  max-width: 572px;
  margin: auto;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
  .title {
    color: $color_3;
    font-size: 18px;
    background-color: $background-color_3;
    padding: 23px 0;
  }
  .terms-condition {
    font-size: 16px;
    padding-top: 28px;
    a {
      color: $color_5;
    }
  }
}
.signup-inner--form {
  padding: 42px 100px 50px;
  background-color: $background-color_6;
  position: relative;
  &:before {
    top: 50%;
    left: 50%;
    content: "";
    position: absolute;
    height: 170px;
    width: 174px;
    background-repeat: no-repeat;
    -webkit-transform: translate(-50%, -80%);
    -ms-transform: translate(-50%, -80%);
    transform: translate(-50%, -80%);
  }
  .single-field {
    width: 100%;
    border: none;
    background-color: $background-color_2;
    border-bottom: 1px solid #999;
    padding: 5px 10px;
    margin-bottom: 28px;
  }
  .nice-select {
    width: 100%;
    border: none;
    border-bottom: 1px solid #999;
    border-radius: 0;
    font-size: 13px;
    padding: 5px 10px;
    height: 34px;
    line-height: 30px;
    margin-bottom: 27px;
    background-color: $background-color_2;
    span {
      color: $color_4;
    }
    &:after {
      height: 6px;
      width: 6px;
      border-bottom: 1px solid #999;
      border-right: 1px solid #999;
    }
    .list {
      width: 100%;
    }
  }
}
.submit-btn {
  font-size: 16px;
  font-weight: 500;
  color: $color_3;
  line-height: 1;
  padding: 15px 20px;
  display: block;
  border: 1px solid transparent;
  background-color: $background-color_3;
  width: 100%;
  margin-top: 23px;
  &:hover {
    background-color: $background-color_4;
  }
}
.timeline-logo-area {
  padding: 0 30px;
  .tagline {
    color: $color_2;
    font-size: 16px;
    font-weight: 500;
    padding-left: 70px;
  }
}
.login-area {
  background-color: $background-color_3;
  padding: 15px 30px;
  .single-field {
    width: 100%;
    border: none;
    background-color: $background-color_2;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    color: $color_3;
  }
  .login-btn {
    font-size: 14px;
    font-weight: 500;
    color: $color_5;
    line-height: 1;
    padding: 12px 32px;
    display: inline-block;
    border: 1px solid transparent;
    background-color: $background-color_6;
    &:hover {
      background-color: $background-color_4;
    }
  }
  &::-webkit-input-placeholder {
    color: $color_7;
  }
  &:-ms-input-placeholder {
    color: $color_7;
  }
  &::-moz-placeholder {
    color: $color_7;
  }
  &::-ms-input-placeholder {
    color: $color_7;
  }
  &::placeholder {
    color: $color_7;
  }
}
.timeline-bg-content {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  padding: 0 30px;
  .timeline-bg-title {
    top: 50%;
    left: 50%;
    width: 610px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: $color_3;
    text-align: center;
    font-size: 36px;
  }
}
.about-author-details {
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-author-menu {
  li {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 4px;
    margin-bottom: 16px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8;
      color: $color_2;
      text-transform: capitalize;
    }
    a.active {
      color: $color_5;
    }
    &:hover {
      border-color: $border-color_1;
      a {
        color: $color_5;
      }
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.about-description {
  background-color: $background-color_6;
  padding: 42px 68px 36px 68px;
}
.author-desc-title {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 16px;
  margin-bottom: 20px;
  .author {
    font-size: 18px;
    font-weight: 700;
  }
}
.content-box {
  padding: 30px;
  background-color: $background-color_6;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
}
.content-title {
  font-size: 18px;
  font-weight: 700;
  color: $color_2;
  line-height: 1.3;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 48px;
  padding-bottom: 25px;
  text-transform: capitalize;
  border-bottom: 1px solid #dddddd;
}
.photo-group {
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    .gallery-overlay {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
    .view-icon {
      opacity: 1;
      visibility: visible;
    }
    .photos-caption {
      color: $color_5;
    }
  }
}
.photo-group.active {
  .view-icon {
    opacity: 1;
    visibility: visible;
  }
  .gallery-overlay {
    &:before {
      opacity: 0;
      visibility: hidden;
    }
  }
}
.gallery-overlay {
  position: relative;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    pointer-events: none;
    background-color: $background-color_9;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
}
.photo-gallery-caption {
  text-align: center;
  padding: 13px 15px 17px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  .photos-caption {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: $color_2;
    text-transform: capitalize;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
}
.view-icon {
  top: 50%;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
  margin: auto;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  &:before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: $background-color_6;
  }
  &:after {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    content: "";
    position: absolute;
    background-color: $background-color_6;
  }
}
.load-more {
  line-height: 1;
  margin-top: 28px;
}
.load-more-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: $color_2;
  text-transform: capitalize;
  &:hover {
    color: $color_5;
  }
}
.friends-section {
  .content-box {
    padding: 30px 0;
  }
  .content-box.friends-zone {
    padding: 0;
    background-color: $background-color_2;
    -webkit-box-shadow: none;
    box-shadow: none;
    .friend-list-view {
      background-color: $background-color_6;
      &:hover {
        background-color: $background-color_3;
      }
    }
  }
}
.friend-list-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: $background-color_6;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.2);
  &:hover {
    background-color: $background-color_3;
    .author {
      a {
        color: $color_3;
      }
    }
    .add-frnd {
      color: $color_3;
    }
  }
  .add-frnd {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8;
    color: $color_6;
    text-transform: capitalize;
  }
}
.filter-menu {
  margin-left: auto;
  padding-right: 50px;
  button {
    font-size: 14px;
    font-weight: 500;
    padding: 23px 0;
    text-transform: capitalize;
    margin-right: 50px;
    &:hover {
      color: $color_5;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  button.active {
    color: $color_5;
  }
}
.reveal-footer {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  position: fixed;
  background-color: $background-color_1;
}
.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer-card {
  height: 75px;
  padding: 0 20px;
  border-top: none;
  background-color: $background-color_6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 325px;
  -webkit-box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
  box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
}
.active-profile-wrapper {
  width: 1170px;
  -webkit-box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
  box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
}
.active-profile-mob-wrapper {
  -webkit-box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
  box-shadow: 0px -5px 36px -5px rgba(51, 51, 51, 0.2);
}
.active-profile-carousel {
  padding: 0 60px;
}
.friends-search {
  width: 100%;
}
.frnd-search-title {
  height: 62px;
  padding: 0 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: $background-color_3;
  .frnd-search-icon {
    color: $color_3;
    font-size: 20px;
    &:hover {
      color: $color_2;
    }
  }
  .close-btn {
    color: $color_3;
    font-size: 20px;
    &:hover {
      color: $color_2;
    }
  }
  p {
    color: $color_3;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}
.search-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.search-field {
  width: 100%;
  border: none;
  padding-right: 10px;
  color: $color_6;
}
.search-btn {
  color: $color_2;
  font-size: 20px;
  &:hover {
    color: $color_5;
  }
}
.friend-search-list {
  bottom: calc(100% + 10px);
  left: 0;
  width: 100%;
  background-color: $background-color_6;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  ul {
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .add-frnd {
        display: block;
        font-size: 12px;
        padding-top: 5px;
        color: $color_6;
        text-transform: capitalize;
        &:hover {
          color: $color_5;
        }
      }
    }
  }
}
.friend-search-list.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.frnd-search-inner {
  height: 350px;
  margin: 25px 0;
  padding: 0 25px;
  ul {
    li {
      cursor: pointer;
      .posted-author {
        p {
          color: $color_6;
          font-size: 13px;
          padding-top: 5px;
        }
      }
    }
  }
}
.chat-output-box {
  bottom: calc(100% + 10px);
  left: 0;
  width: 100%;
  background-color: $background-color_6;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}
.chat-output-box.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.live-chat-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 25px;
  background-color: $background-color_3;
  .author {
    a {
      color: $color_3;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .active-pro {
    font-size: 12px;
    line-height: 1;
    color: $color_3;
    text-transform: capitalize;
  }
}
.chat-settings {
  color: $color_3;
  font-size: 20px;
  margin-right: 15px;
  &:hover {
    color: $color_2;
  }
}
.close-btn {
  color: $color_3;
  font-size: 20px;
  &:hover {
    color: $color_2;
  }
}
.live-chat-inner {
  width: 100%;
}
.chat-text-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  .chat-message-send {
    background-color: $background-color_6;
    padding-right: 10px;
  }
}
.live-chat-field {
  width: 100%;
  border: none;
  height: 60px !important;
  padding-top: 25px;
  padding-left: 10px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  &:focus {
    padding-top: 0;
  }
}
.message-list {
  margin: 25px 0;
  padding: 0 25px;
  height: 350px;
  display: block;
  li {
    margin-bottom: 35px;
    position: relative;
    &:last-child {
      margin-bottom: calc(35px - 20px);
    }
    .message-time {
      bottom: -15px;
      right: 15px;
      position: absolute;
      color: $color_2;
      font-size: 11px;
      line-height: 1;
    }
  }
  li.text-friends {
    max-width: 210px;
    padding: 20px;
    background-color: $background-color_1;
    border-radius: 10px;
    p {
      font-size: 12px;
      color: $color_2;
    }
  }
  li.text-author {
    max-width: 210px;
    padding: 20px;
    border-radius: 10px;
    background-color: $background-color_10;
    margin-left: auto;
    p {
      font-size: 12px;
      color: $color_3;
    }
  }
}
.active-profiles-wrapper {
  .single-slide {
    text-align: center;
  }
}
.mobile-footer-inner {
  position: relative;
  .mobile-frnd-search {
    width: 60px;
    height: 69px;
    line-height: 69px;
    background-color: $background-color_3;
    text-align: center;
    i {
      color: $color_3;
    }
    .search-box {
      display: block;
    }
  }
  .active-profile-mob-wrapper {
    width: calc(100% - 60px);
  }
}
.search-toggle-btn {
  font-size: 20px;
  width: 100%;
}
.mob-frnd-search-inner {
  left: 75px;
  top: 0;
  height: 100%;
  width: calc(100% - 60px);
  position: absolute;
  z-index: 9;
  background-color: $background-color_6;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.mob-frnd-search-inner.show {
  opacity: 1;
  visibility: visible;
}
.mob-frnd-search-field {
  height: 60px;
  border: none;
  width: 100%;
  padding: 15px;
}
.mob-btn {
  width: 50px;
}
@media only screen and (max-width: 767.98px) {
  .scroll-top {
    display: none;
  }
  .container {
    max-width: 500px;
  }
  .pt-20 {
    padding-top: 10px;
  }
  .pt-30 {
    padding-top: 10px;
  }
  .mb-20 {
    margin-bottom: 10px;
  }
  .mt-20 {
    margin-top: 10px;
  }
  .header-top-navigation {
    text-align: center;
    margin-top: 50px;
    ul {
      li {
        &:before {
          display: block;
        }

        margin-right: 15px;
        padding-right: 15px;
        span {
          font-size: 14px;
          font-weight: 500;
          padding: 10px 0;
        }
      }
    }
  }
  .secondary-menu-wrapper {
    padding: 0 30px;
    .header-top-navigation {
      margin-top: 0;
      text-align: center;
      padding: 20px 0;
      li {
        margin-right: 10px;
        padding-right: 10px;
        &:before {
          display: none;
        }
        &:nth-child(3) {
          margin-right: 10px;
          padding-right: 10px;
        }
        a {
          padding: 0;
          span {
            display: none;
            padding: 0;
          }
          i {
            display: block;
          }
        }
      }
    }
  }
  .main-wrapper {
    padding-bottom: 10px;
    margin-bottom: 60px;
  }
  .card {
    margin-bottom: 10px;
  }
  .profile-menu-area {
    padding: 15px;
  }
  .profile-banner-large {
    height: 190px;
  }
  .edit-btn {
    padding: 11px 15px;
  }
  .profile-picture-box {
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
  }
  .profile-picture {
    &:before {
      border: 5px solid #fff;
    }
  }
  .widget-area {
    margin-top: 10px;
  }
  .signup-form-wrapper {
    padding: 47px 30px;
  }
  .signup-inner--form {
    padding: 42px 50px 50px;
  }
  .timeline-logo-area {
    padding: 10px 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .tagline {
      padding-left: 0;
    }
  }
  .timeline-bg-content {
    height: 400px;
    .timeline-bg-title {
      width: 100%;
      padding: 0 30px;
      font-size: 26px;
    }
  }
  .about-description {
    padding: 30px;
    margin-top: 20px;
  }
  .photo-gallery-caption {
    .photos-caption {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .view-icon {
    width: 40px;
    height: 40px;
  }
  .friend-list-view {
    .posted-author {
      margin-left: 8px;
    }
  }
  .filter-menu {
    padding-right: 30px;
    button {
      margin-right: 15px;
      padding: 5px 0;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .container {
    max-width: 600px;
  }
  .header-top-navigation {
    ul {
      li {
        margin-right: 15px;
        padding-right: 15px;
        span {
          font-size: 14px;
          font-weight: 500;
          padding: 18px 0;
        }
      }
    }
  }
  .secondary-menu-wrapper {
    padding: 0 30px;
    .header-top-navigation {
      padding-right: 30px;
      li {
        margin-right: 5px;
      }
    }
  }
  .main-wrapper {
    padding-bottom: 10px;
    margin-bottom: 60px;
  }
  .profile-menu-area {
    margin-bottom: 50px;
  }
  .profile-banner-large {
    height: 270px;
  }
  .widget-area {
    margin-top: 30px;
  }
  .signup-form-wrapper {
    margin-bottom: 30px;
    padding: 47px 30px;
  }
  .signup-inner--form {
    padding: 42px 50px 50px;
  }
  .timeline-logo-area {
    padding: 10px 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .tagline {
      padding-left: 0;
    }
  }
  .timeline-bg-content {
    height: 400px;
  }
  .about-description {
    padding: 30px;
    margin-top: 20px;
  }
  .photo-gallery-caption {
    .photos-caption {
      font-size: 13px;
    }
  }
  .view-icon {
    width: 40px;
    height: 40px;
  }
  .filter-menu {
    padding-right: 30px;
    button {
      margin-right: 20px;
    }
  }
  .footer-wrapper {
    display: block;
  }
  .footer-card {
    width: 100%;
    height: 60px;
    margin-top: 20px;
  }
  .active-profile-wrapper {
    width: 100%;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575.98px) {
  .container {
    max-width: 450px;
  }
  .page-title {
    font-size: 15px;
  }
  .profile-picture {
    width: 100px;
  }
  .request-btn-inner {
    .frnd-btn {
      padding: 8px 15px;
    }
  }
  .signup-form-wrapper {
    .create-acc {
      font-size: 26px;
    }
  }
  .timeline-logo-area {
    .timeline-logo {
      width: 70px;
    }
    .tagline {
      padding-left: 70px;
    }
  }
  .login-area {
    .single-field {
      margin-bottom: 30px;
    }
    .login-btn {
      width: 100%;
    }
  }
  .timeline-bg-content {
    height: 300px;
  }
  .friend-list-view {
    .posted-author {
      margin-left: 20px;
    }
  }
  .filter-menu {
    button {
      margin-right: 10px;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .header-top-navigation {
    ul {
      li {
        margin-right: 45px;
      }
    }
  }
  .secondary-menu-wrapper {
    padding-left: 250px;
  }
  .profile-picture-box {
    -webkit-transform: translateY(calc(-50% - 10px));
    -ms-transform: translateY(calc(-50% - 10px));
    transform: translateY(calc(-50% - 10px));
  }
  .author-into-list {
    li {
      a {
        i {
          padding-right: 8px;
        }
      }
    }
  }
  .widget-title {
    font-size: 16px;
  }
  .list-title {
    font-size: 13px;
    font-weight: 500;
  }
  .signup-form-wrapper {
    padding: 0 30px;
    padding: 47px 30px;
  }
  .signup-inner--form {
    padding: 42px 50px 50px;
  }
  .timeline-bg-content {
    .timeline-bg-title {
      width: 100%;
      padding: 0 30px;
      font-size: 26px;
    }
  }
  .friend-list-view {
    padding: 20px 15px;
  }
  .filter-menu {
    button {
      margin-right: 40px;
    }
  }
  .footer-card {
    width: 212px;
  }
  .active-profile-wrapper {
    width: calc(100% - 464px);
  }
}
@media only screen and (max-width: 479.98px) {
  .share-text-box {
    .btn-share {
      display: none;
    }
  }
  .post-meta {
    .post-meta-like {
      span {
        display: none;
      }
      strong {
        display: inline-block;
      }
    }
  }
  .signup-inner--form {
    padding: 42px 30px 50px;
  }
  .timeline-logo-area {
    .tagline {
      padding-left: 20px;
    }
  }
  .timeline-bg-content {
    .timeline-bg-title {
      width: 100%;
      font-size: 22px;
      padding: 0 30px;
    }
  }
  .secondary-menu-2 {
    display: block;
    text-align: center;
    padding: 15px 0;
    .post-settings-bar {
      display: none;
    }
  }
  .filter-menu {
    padding-right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499.98px) {
  .signup-form-wrapper {
    padding: 47px 30px;
  }
  .timeline-logo-area {
    .tagline {
      padding-left: 50px;
    }
  }
  .timeline-bg-content {
    .timeline-bg-title {
      width: 540px;
      font-size: 32px;
    }
  }
  .footer-card {
    width: 270px;
  }
  .active-profile-wrapper {
    width: calc(100% - 600px);
  }
}
@media only screen and (min-width: 1600px) {
  .timeline-logo-area {
    padding: 0 107px;
  }
  .login-area {
    padding: 15px 110px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
  .active-profile-wrapper {
    width: calc(100% - 690px);
  }
}
